body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  height: 100%;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ui.fullscreen.modal, .ui.fullscreen.scrolling.modal {
  left: 2.5% !important;
}
.ui.dimmer .ui.loader:after {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-animation: loader .6s linear;
  animation: loader .6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: .2em;
  box-shadow: 0 0 0 1px transparent;
}
.table-cell {
  text-align: center;
  padding: 10px 0;
}
.table-head .table-cell {
  cursor: auto;
  background: #f9fafb;
  color: rgba(0,0,0,.87);
  vertical-align: center;
  font-weight: 700;
  text-transform: none;
  border-bottom: 1px solid rgba(34,36,38,.1);
  border-left: none;
  width: 100%;
}
.table-th {
  border: 1px solid gray;
  padding: 0 !important;
}
.table-cell-input {
  border: 1px solid gray !important;
  padding: 0 !important;
  margin: 0 !important;
}
.table-cell-input div.ui .input {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
.table-cell-input-no-border {
  border: none !important;
}
.table-cell-input .ui.input {
  width: 100%;
}
.table-cell-input .ui.input>input {
  padding: 10px;
  width: 100%;
  height: 100%;
  border: 0;
  text-align: center;
}
